<template>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Profile</h5>
          </div>
          <div class="card-body">
            <div class="card-text">
              <form novalidate>
                <div class="row mt-2">
                  <div class="col">
                    <label for="username">Username</label>
                    <input id="username" type="text" v-model="user.username" class="form-control" disabled="disabled">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="acl_uuid">ACL UUID</label>
                    <input id="acl_uuid" type="text" v-model="user.acl_uuid" class="form-control" disabled="disabled">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="email">Email</label>
                    <input id="email" type="email" v-model="user.email" class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="firstname">First name</label>
                    <input id="firstname" type="text" v-model="user.firstname"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="lastname">Last name</label>
                    <input id="lastname" type="text" v-model="user.lastname"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="phone">Phone number</label>
                    <input id="phone" type="tel" v-model="user.telephone"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <b-form-group id="input-group-1"
                                  label="Measurement system"
                                  label-for="input-1">
                      <b-form-select v-model="user.measurement_system">
                        <b-form-select-option value="metric">Metric</b-form-select-option>
                        <b-form-select-option value="imperial">Imperial</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4 offset-4">
                    <button type="button"
                            @click="saveUser"
                            :disabled="saving"
                            class="btn btn-primary w-100">Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Change password</h5>
          </div>
          <div class="card-body">
            <div class="card-text">
              <form novalidate>
                <div class="row mt-2">
                  <div class="col">
                    <label for="password">Current password</label>
                    <input id="password" type="password" v-model="changePassword.password"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="passwordnew">New password</label>
                    <input id="passwordnew" type="password" v-model="changePassword.passwordnew"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="passwordconfirm">New password confirmation</label>
                    <input id="passwordconfirm" type="password"
                           v-model="changePassword.passwordconfirm"
                           class="form-control">
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4 offset-4">
                    <button type="button"
                            @click="updatePassword"
                            :disabled="saving"
                            class="btn btn-primary w-100">
                      Update password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Profile',
  data: () => ({
    user: {},
    changePassword: {
      password: '',
      passwordnew: '',
      passwordconfirm: '',
    },
    saving: false,
  }),
  beforeMount() {
    this.requestUser();
  },
  methods: {
    updatePassword() {
      axios.post('v1/users/change-password', this.changePassword)
        .then(() => {
          this.$noty.success('Password updated!');
          this.$store.dispatch('Auth/doLogin', {
            username: this.$store.getters['User/profile'].username,
            password: this.changePassword.passwordnew,
            environment: this.$store.getters['Core/selectedAPI'],
          });
          this.changePassword.password = '';
          this.changePassword.passwordnew = '';
          this.changePassword.passwordconfirm = '';
        }).finally(() => {
          this.saving = false;
        });
    },
    requestUser() {
      axios.get('v1/users/me').then(response => {
        const { data } = response;
        data.user.languages = (data.user.languages || []).map(language => language.code);
        this.user = data.user;
      });
    },
    saveUser() {
      this.saving = true;
      axios.put('v1/users/me', this.user)
        .then(() => {
          this.$noty.success('User updated with success!');
        }).catch(err => {
          if (err.response) {
            const { data } = err.response;
            if (data.error_description) {
              const msg = Object.keys(data.error_description).map(key => data.error_description[key][0]).join('<br>');
              this.$noty.error(msg);
            }
          }
        }).finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
